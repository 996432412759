<template>
  <!-- DATA - RIGHT SCROLLBAR -->
  <!-- :max-height="$vuetify.breakpoint.smAndDown ? 110 : 630" -->
  <v-card
    elevation="0"
    class="overflow-y-auto"
    color="#1a1b1d"
    style="margin-left: 5px"
    min-height="630"
    :max-height="$vuetify.breakpoint.smAndDown ? `100%` : `630`"
    :style="
      $vuetify.breakpoint.smAndDown
        ? `border-left: `
        : `border-left:1px solid gray`
    "
  >
    <v-card-text>
      <div>
        <div class="">
          <h2 class="HeadTextStyle" :style="fontSize">Experience</h2>
        </div>

        <div class="section myservices">
          <div class="container" style="padding-top: 30px">
            <div class="experience box">
              <v-row class="headRow mx-0 mb-3">
                <i class="greenicon far fa-address-card pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  Front-End Developer
                </p>
              </v-row>
              <p class="desc-1">
                <span class="textInsideProject">Company: </span>,,The
                Anemone”<br />
                <span class="textInsideProject">Location: </span>Belgrade <br />
                <span class="textInsideProject">Start: </span>Mar 2023 <br />
                <span class="textInsideProject">End: </span>-<br />
                <span class="textInsideProject">Responsibility: </span
                >Developing custom websites using HTML, CSS and JavaScript in my
                current role. As part of my responsibilities, I have opportunity
                to work with WordPress, where I have learned how to leverage its
                powerful features and customization options to create unique web
                solutions. Additionally, I've been acquiring skills in upgrading
                plugins to enhance functionality and improve the performance of
                WordPress-based websites.
                <br />
                <span class="textInsideProject">Using: </span>HTML, CSS, JS,
                JQuery, Wordpress <br />
              </p>
            </div>
            <div class="experience box">
              <v-row class="headRow mx-0 mb-3">
                <i class="greenicon far fa-address-card pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  Front-End Developer
                </p>
              </v-row>
              <p class="desc-1">
                <span class="textInsideProject">Company: </span>,,Goxee” <br />
                <span class="textInsideProject">Location: </span>Belgrade <br />
                <span class="textInsideProject">Start: </span>Oct 2021 <br />
                <span class="textInsideProject">End: </span>Feb 2023<br />
                <span class="textInsideProject">Responsibility: </span
                >Developing and maintaining websites for the car industry,
                including website generator and theme creation.
                <br />
                <span class="textInsideProject">Using: </span>HTML, CSS, JS,
                JQuery, Vue <br />
                <!-- <i>Language: </i> JavaScript <br />
                <i>Framework: </i>Meteor, Bootstrap <br /> -->
              </p>
            </div>

            <div class="experience box">
              <v-row class="headRow mx-0 mb-3">
                <i class="greenicon far fa-address-card pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  Front-End Developer
                </p>
              </v-row>
              <p class="desc-1">
                <span class="textInsideProject">Company: </span>,,Mile
                Development” (Part-Time) <br />
                <span class="textInsideProject">Location: </span>Belgrade <br />
                <span class="textInsideProject">Start: </span>Dec 2021 <br />
                <span class="textInsideProject">End: </span>Jan 2022<br />
                <span class="textInsideProject">Responsibility: </span
                >(Short-term project) Fixing bugs in an existing project.
                <br />
                <span class="textInsideProject">Using: </span>HTML, JS, Vue,
                Vuex, Vuetify <br />
                <!-- <i>Language: </i> JavaScript <br />
                <i>Framework: </i>Meteor, Bootstrap <br /> -->
              </p>
            </div>

            <div class="experience box">
              <v-row class="headRow mx-0 mb-3">
                <i class="greenicon far fa-address-card pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  Front-End Developer
                </p>
              </v-row>
              <p class="desc-1">
                <span class="textInsideProject">Company: </span>,,Gvisp1”<br />
                <span class="textInsideProject">Location: </span>Belgrade <br />
                <span class="textInsideProject">Start: </span>February 2021
                <br />
                <span class="textInsideProject">End: </span>April 2021<br />
                <span class="textInsideProject">Responsibility: </span>I
                contributed to the development of a responsive single-page
                application with a mobile-first approach. Our team worked on
                creating a platform where members could purchase discounted
                products such as food, clothing, shoes, technology, etc. The
                project involved implementing register and login functionality,
                CRUD operations, and ensuring a smooth user experience.
                <br />
                <span class="textInsideProject">Using: </span> HTML, CSS, JS,
                Meteor <br />
              </p>
            </div>

            <div class="experience box">
              <v-row class="headRow mx-0 mb-3" style="flex-wrap: nowrap">
                <i class="greenicon far fa-address-card pr-3"></i>
                <p class="headText" style="border-bottom: none">Freelancing</p>
              </v-row>
              <p class="desc-1">
                <span class="textInsideProject">Start: </span>February 2020
                <br />
                <span class="textInsideProject">End: </span>-<br />
                <span class="textInsideProject">Responsibility: </span
                >Developing responsive single-page applications for personal use
                by customers, communities, and companies, while also teaching
                individuals the fundamentals of the Vue framework and guiding
                them on implementing Vue in their projects.<br />
                <span class="textInsideProject">Using: </span> HTML, CSS, JS,
                Vue, Vuex, Vuetify, MySql, Firebase <br />
                <!-- <span class="textInsideProject">Language: </span> JavaScript
                <br />
                <span class="textInsideProject">Framework: </span>Vue <br />
                <span class="textInsideProject">Library: </span>Vuex, Vuetify
                and others depend on the project<br />
                <span class="textInsideProject">Database: </span>Relational
                (MySQL as RDBMS)<br />
                <span class="textInsideProject">Other: </span>Firebase
                (Backend-as-a-Service) -->
              </p>
            </div>

            <div class="experience box">
              <v-row class="headRow mx-0 mb-3">
                <i class="greenicon far fa-address-card pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  Full-Stack Internship
                </p>
              </v-row>
              <p class="desc-1">
                <span class="textInsideProject">Company: </span> "Enon
                Solutions"<br />
                <span class="textInsideProject">Location: </span>Belgrade <br />
                <span class="textInsideProject">Start: </span>November 2019
                <br />
                <span class="textInsideProject">End: </span>January 2020<br />
                <span class="textInsideProject">Responsibility: </span>
                Developed a modern and responsive web application utilizing
                HTML, CSS, and JavaScript with the Vue framework for the
                front-end. For the back-end, we utilized the Mars server
                (www.mars-server.net), which supports JavaScript as a back-end
                language. The application's relational database is powered by
                MySQL as the RDBMS.<br />
                <span class="textInsideProject">Project: </span>Cookbook <br />
                <span class="textInsideProject">Short description: </span> It
                was a full-stack project in a group of 3 people. Users can
                register, login, CRUD recipes, rate other recipes, upload
                pictures of made meals, share meals etc.
              </p>
            </div>
            <div class="experience box">
              <v-row class="headRow mx-0 mb-3">
                <i class="greenicon far fa-address-card pr-3"></i>
                <p class="headText" style="border-bottom: none">
                  Borland Delphi programmer
                </p>
              </v-row>

              <p class="desc-1">
                <span class="textInsideProject">Company:</span> S.R. "Processor"
                <br />
                <span class="textInsideProject">Location: </span>Pozarevac<br />
                <span class="textInsideProject">Start: </span>January 2005
                <br />
                <span class="textInsideProject">End: </span>Avgust 2008<br />
                <span class="textInsideProject">Responsibility:</span> Creating
                and maintaining desktop applications.
              </p>
            </div>

            <!-- Other Work Experience -->
            <div>
              <div class="mr-5 mt-7 pb-3">
                <h2 class="HeadTextStyle" :style="fontSize">
                  Other Work Experience
                </h2>
              </div>

              <div class="experience box">
                <v-row class="headRow mx-0 mb-3" style="padding-top: 20px">
                  <i class="greenicon far fa-address-card pr-3"></i>
                  <p class="headText" style="border-bottom: none">Owner</p>
                </v-row>
                <p class="desc-1">
                  <span class="textInsideProject">Company: </span> T.R.
                  "Bio-dućan plus"<br />
                  <span class="textInsideProject">Location: </span
                  >Pozarevac<br />
                  <span class="textInsideProject">Start: </span>Avgust 2012
                  <br />
                  <span class="textInsideProject">End: </span>May 2019<br />
                  <span class="textInsideProject">Responsibility: </span>
                  Everything from ordering, selling, controlling quality, money
                  management, etc.
                  <br />
                  <br />
                  However, I eventually made the decision to sell my shop so
                  that I could fully focus on my true passion, which is
                  programming.
                </p>
              </div>

              <div class="experience box" style="padding-top: 20px">
                <v-row class="headRow mx-0 mb-3">
                  <i class="greenicon far fa-address-card pr-3"></i>
                  <p class="headText" style="border-bottom: none">
                    Warehouse manager
                  </p>
                </v-row>

                <p class="desc-1">
                  <span class="textInsideProject">Company: </span> "Idea" D.O.O.
                  <br />
                  <span class="textInsideProject">Start: </span>Mart 2011 <br />
                  <span class="textInsideProject">End: </span>January 2012<br />
                  <span class="textInsideProject">Responsibility: </span> In my
                  role, I was responsible for organizing and managing teams of
                  people, ensuring efficient warehouse stock supplies,
                  maintaining strict quality control measures for products, and
                  handling product ordering processes, among other tasks.
                </p>
              </div>
              <div class="experience box" style="padding-top: 20px">
                <v-row class="headRow mx-0 mb-3">
                  <i class="greenicon far fa-address-card pr-3"></i>
                  <p class="headText" style="border-bottom: none">
                    Truck route coordinator
                  </p>
                </v-row>
                <p class="desc-1">
                  <span class="textInsideProject">Company: </span>
                  "International" D.O.O. <br />
                  <span class="textInsideProject">Info: </span>International
                  Company from Italy, branch in Serbia<br />
                  <span class="textInsideProject">Start: </span>January 2003
                  <br />
                  <span class="textInsideProject">End: </span>February 2004<br />
                  <span class="textInsideProject">Responsibility: </span> My
                  role involved ensuring that trucks remained fully loaded with
                  merchandise by effectively managing buyers and sellers.
                  Additionally, when trucks traveled across Europe, I was
                  responsible for strategically determining the optimal travel
                  routes to minimize costs and maximize profitability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return { "font-size": "1.35em !important" };
        default:
          return { "font-size": "1.4em" };
      }
    },
  },
};
</script>

<style></style>
